import axios from 'axios';
let oVue = null;
var instance = axios.create();

instance.defaults.timeout = 60000; //前端超时限制

//设置axios的请求前拦截
instance.interceptors.request.use(
  config => {
    config.headers.system = "management";
    config.headers.token = config.headers.token || window.localStorage.getItem('token')
    return config;
  },
  error => {
    return Promise.reject(error)
  }
)

//设置axios的返回拦截
instance.interceptors.response.use(
  response => {
    return response;     
  },
  error => {
    oVue.$message({
      message: error.response.data.msg,
      type: 'error'
    });
    return Promise.reject(error);    
  }    
)

function install(Vue){
  oVue = new Vue();
  Vue.prototype.$http = instance;
}

export default install;