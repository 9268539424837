
import dayjs from 'dayjs';
const $common = function(Vue){
  let $methods = {
    dayjs: dayjs,
    setItem(key="", value="", channel="default"){
      return new Promise((resolve) => {
        Vue.prototype.$http.post("/api/common/storage/setItem", {key: key, value: value, channel:channel}).then(()=>{
          resolve(value)
        }).catch(()=>{
          resolve()
        })
      })
    },
    getItem(key="", channel="default"){
      return new Promise((resolve) => {
        Vue.prototype.$http.post("/api/common/storage/getItem", {key: key, channel:channel}).then(({data})=>{
          resolve(data && data.result)
        }).catch(()=>{
          resolve()
        })
      })
    },
    copy(obj){
      var newObj = null;
      if(typeof obj !== 'object' || obj === null){   
        return obj;	 //如果传入obj不是object或者为null就退出递归,
      }
      newObj = Array.isArray(obj) ? [] : {};	
            
      for(var key in obj){
        newObj[key] = $methods.copy(obj[key]);  //调用函数本身
      }
      return newObj;
    },
    success(message){
      Vue.$message({
        message: message || '操作成功',
        type: 'success'
      });
    }
  }
  return $methods;
}

function install(Vue){
  Vue.prototype.$common = $common(Vue);
}

export default install;