const slideContent = r => require.ensure([], () => r(require("@/views/main/slideContent.vue")), "views/product/index");
const productList = r => require.ensure([], () => r(require("./productInformation/productList.vue")), "views/product/productList");
const productDetails = r => require.ensure([], () => r(require("./productInformation/productDetails.vue")), "views/product/productDetails");
const productClassificationList = r => require.ensure([], () => r(require("./productClassification/productClassificationList.vue")), "views/product/productClassificationList");
const productClassificationDetails = r => require.ensure([], () => r(require("./productClassification/productClassificationDetails.vue")), "views/product/productClassificationDetails");
const productChannelsList = r => require.ensure([], () => r(require("./productChannels/productChannelsList.vue")), "views/product/productChannelsList");
const productChannelsDetails = r => require.ensure([], () => r(require("./productChannels/productChannelsDetails.vue")), "views/product/productChannelsDetails");

export default [
  {
    path: '/product',
    meta: {
      title: '商品管理中心',
      menuShow: true
    },
    name: 'product',
    sort: 3,
    redirect: '/product/productList',
    component: slideContent,
    children: [
      {
        path: 'productList',
        name: 'productList',
        component: productList,
        meta: {
          title: '商品列表',
          menuShow: true
        }
      },
      {
        path: 'productDetails/:id',
        name: 'productDetails',
        component: productDetails,
        meta: {
          title: '商品详情'
        }
      },
      {
        path: 'productClassification',
        name: 'productClassification',
        component: productClassificationList,
        meta: {
          title: '分组管理',
          menuShow: true
        }
      },
      {
        path: 'productClassificationDetails/:id',
        name: 'productClassificationDetails',
        component: productClassificationDetails,
        meta: {
          title: '分组管理详情',
        }
      },
      {
        path: 'productChannels',
        name: 'productChannels',
        component: productChannelsList,
        meta: {
          title: '卖场管理',
          menuShow: true
        }
      },
      {
        path: 'productChannelsDetails/:id',
        name: 'productChannelsDetails',
        component: productChannelsDetails,
        meta: {
          title: '卖场管理详情'
        }
      },
    ]
  },
]