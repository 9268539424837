const slideContent = r => require.ensure([], () => r(require("@/views/main/slideContent.vue")), "views/customer/index");
const customerList = r => require.ensure([], () => r(require("./customer/customerList.vue")), "views/customer/customerList");
const customerDetails = r => require.ensure([], () => r(require("./customer/customerDetails.vue")), "views/customer/customerDetails");
const loginInfo = r => require.ensure([], () => r(require("./loginInfo/loginInfoList.vue")), "views/loginInfo/loginInfoList");

export default [
  {
    path: '/customerManage',
    name: 'customerManage',
    meta: {
      title: '用户管理中心',
      menuShow: true
    },
    sort: 2,
    redirect: '/customerManage/customerList',
    component: slideContent,
    children: [
      {
        path: 'customerList',
        name: 'customerList',
        component: customerList,
        meta: {
          title: '用户管理',
          menuShow: true
        }
      },
      {
        path: 'customerDetails/:id',
        name: 'customerDetails',
        component: customerDetails,
        meta: {
          title: '用户管理详情'
        }
      },
      {
        path: 'loginInfo',
        name: 'loginInfo',
        component: loginInfo,
        meta: {
          title: '用户登录信息',
          menuShow: true
        }
      }
    ]
  },
]