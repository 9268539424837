const Index = r => require.ensure([], () => r(require("./Index.vue")), "views/main/Index");

export default [
  {
    path: '/',
    name: 'mainIndex',
    meta: {
      title: '首页',
      menuShow: true
    },
    component: Index,
    sort: 1
  }
]