import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const files = require.context(`@/views`, true, /store.config.js$/);
let storeModules = {};
files.keys().forEach(key => {
  const name = (/\/(.+)\/store.config.js/.exec(key))[1];
  const storeModule = files(key).default || files(key);
  storeModules[name] = storeModule;
})

let userInfo = window.sessionStorage.getItem('userInfo'),
  routeHistory = window.sessionStorage.getItem('routeHistory');
export default new Vuex.Store({
  state: {
    userInfo: userInfo ? JSON.parse(userInfo) : {},
    routeHistory: routeHistory ? JSON.parse(routeHistory) : [],
  },
  getters: {
    // ...Store.getters,
  },
  actions: {
    // ...Store.actions,
  },
  mutations: {
    saveUserInfo(state, userInfo){
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      state.userInfo = userInfo;
    },
    insertRouteHistory(state, item){
      state.routeHistory.push(item);
      //sessionStorage.setItem('routeHistory', JSON.stringify(state.routeHistory));
    },
    deleteRouteHistory(state, index){
      state.routeHistory.splice(index, 1);
      //sessionStorage.setItem('routeHistory', JSON.stringify(state.routeHistory));
    }
  },
  modules:  { 
    namespaced: true, 
    ...storeModules
  }
});
