const slideContent = r => require.ensure([], () => r(require("@/views/main/slideContent.vue")), "views/pageManage/index");
const configPage = r => require.ensure([], () => r(require("./configPage/configPage.vue")), "views/pageManage/configPage");
const configPageDetails = r => require.ensure([], () => r(require("./configPage/configPageDetails.vue")), "views/pageManage/configPageDetails");


export default [
  {
    path: '/pageManage',
    name: 'pageManage',
    meta: {
      title: '生成式页面中心',
      menuShow: true
    },
    sort: 4,
    redirect: '/pageManage/configPage',
    component: slideContent,
    children: [
      {
        path: 'configPage',
        name: 'configPage',
        component: configPage,
        meta: {
          title: '页面管理',
          menuShow: true
        }
      },
      {
        path: 'configPageDetails/:id',
        name: 'configPageDetails',
        component: configPageDetails,
        meta: {
          title: '页面详情'
        }
      },
    ]
  },
]