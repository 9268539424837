import Vue from "vue";
import vueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(vueRouter);

const files = require.context(`@/views`, true, /router.config.js$/);
let routers = [];
files.keys().forEach(key => {
  const router = files(key).default || files(key);
  routers = routers.concat(router);
})
window.mallRouter = routers;
let oRouter = new vueRouter({
  routes: routers
});
oRouter.beforeResolve((to, from, next)=>{
  const { path } = to;
  if(path !== '/' && path !== '/login'){
    let routeHistory = store.state.routeHistory;
    if(!routeHistory.some(item => item.path === path)){
      store.commit('insertRouteHistory', to);
    }
  }
  next();
})
export default oRouter
