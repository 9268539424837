import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/main.css';
import '@/assets/css/element-variables.scss';
import router from './router/index.js';
import store from './store/index.js';
import Element from 'element-ui'
import axios from './util/axios/index.js';
import common from './util/common/index.js';   //公共方法
import components from './components/index.js'; //注册公共组件

Vue.use(Element);
Vue.use(axios);
Vue.use(common);
Vue.use(components);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
