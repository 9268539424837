const state = {
  authorization: ""
}

const getters = {
  authorization(state) {
    return state.authorization;
  }   
}

const actions = {
  // 
}

const mutations = {
  setAuthorization(state, val){
    state.authorization = val;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}