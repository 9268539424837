const slideContent = r => require.ensure([], () => r(require("@/views/main/slideContent.vue")), "views/product/index");
const orderList = r => require.ensure([], () => r(require("./order/orderList.vue")), "views/orderManage/order/orderList");
const orderStatus = r => require.ensure([], () => r(require("./orderStatus/orderStatus.vue")), "views/orderManage/order/orderStatus");

export default [
  {
    path: '/orderManage',
    name: 'orderManage',
    meta: {
      title: '订单管理',
      menuShow: true
    },
    sort: 5,
    redirect: '/orderManage/orderList',
    component: slideContent,
    children: [
      {
        path: 'orderList',
        name: 'orderList',

        meta: {
          title: '订单列表',
          menuShow: true
        },
        component: orderList
      },
      {
        path: 'orderStatus',
        name: 'orderStatus',

        meta: {
          title: '订单状态管理',
          menuShow: true
        },
        component: orderStatus
      },
    ]
  },
]