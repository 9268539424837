<template>
  <div id="app">
    <router-view class="mall-content"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created(){
    window._this = this;
  }
}
</script>

<style scoped lang="scss">
.mall-content {
  min-width: 1200px;
  height: 100vh;
}
</style>
